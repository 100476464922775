import PropTypes from "prop-types";
import React from "react";
import parse from "html-react-parser";
import { Link } from "gatsby";

function FeatureList({
    props,
    width,
    titleSize,
    titleMarginBottom,
    bgBox,
    moreButton,
    moreButtonUrl,
    imgWidth,
    imgHeight,
}) {
    let description =
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Neque quas consectetur fugit. Soluta deserunt natus quo dolores!";

    if (props.description !== "") {
        description = props.description;
    }
    return (
        <div className={`${width} flex flex-col py-4 px-4 md:px-8`}>
            <div
                className="relative mb-2"
                style={{ width: imgWidth, height: imgHeight }}
            >
                {bgBox === true ? (
                    <div
                        className="w-8 h-8 bg-gray-200 rounded-lg absolute left-0 bottom-0"
                        style={{ backgroundColor: `#fadb8a` }}
                    ></div>
                ) : null}
                <img
                    className="w-full h-auto absolute left-0 bottom-0"
                    src={
                        typeof props.img != "undefined"
                            ? `images/${props.img}`
                            : `../icons/myicons/${props.icon}`
                    }
                    alt=""
                />
            </div>

            <h2
                className={`${titleSize} ${titleMarginBottom} leading-tight mt-2 font-display pr-6`}
            >
                {props.title}
            </h2>

            <p className="text-gray-600 dark:text-gray-400">
                {parse(description)}
            </p>

            {moreButton === true ? (
                <div className="mt-8">
                    <Link
                        to={moreButtonUrl || null}
                        className="text-gray-500 hover:text-gray-800 dark:text-gray-400 dark:hover:text-gray-300 no-underline hover:underline border-b border-dashed border-gray-400 inline w-auto pb-1"
                    >
                        Lebih lengkap &rarr;
                    </Link>
                </div>
            ) : null}
        </div>
    );
}

FeatureList.defaultProps = {
    width: `w-full md:w-1/3`,
    titleSize: `text-2xl`,
    titleMarginBottom: `mb-6`,
    imgWidth: `3.25rem`,
    imgHeight: `3.25rem`,
    bgBox: true,
    moreButton: false,
};

FeatureList.propTypes = {
    props: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
    img: PropTypes.string,
    imgWidth: PropTypes.string,
    imgHeight: PropTypes.string,
    width: PropTypes.string,
    titleSize: PropTypes.string,
    titleMarginBottom: PropTypes.string,
    bgBox: PropTypes.bool,
    moreButton: PropTypes.bool,
    moreButtonUrl: PropTypes.string,
};

export default FeatureList;
